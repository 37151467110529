import { ReactNode } from 'react';
import dynamic from 'next/dynamic';

import Layout from '@layouts/responsive/Layout/Layout';

const MobileMainLayout = dynamic(
  () => import('@layouts/mobile/MobileMainLayout/MobileMainLayout')
);

export const mobileMainLayout = (page: {
  props: {
    cookies: { [key: string]: string };
    isSearchPage?: boolean;
    withFooterNavigation?: boolean;
    withFooter?: boolean;
    withFollowButton?: boolean;
    withShareButton?: boolean;
    withBackButton?: boolean;
    isHeaderFixed?: boolean;
    withLayoutTopPadding?: boolean;
  };
}) => (
  <Layout>
    <MobileMainLayout
      cookies={page.props.cookies}
      isSearchPage={page.props.isSearchPage}
      withFooterNavigation={page.props.withFooterNavigation}
      withFooter={page.props.withFooter}
      withFollowButton={page.props.withFollowButton}
      withShareButton={page.props.withShareButton}
      withBackButton={page.props.withBackButton}
      withLayoutTopPadding={page.props.withLayoutTopPadding}
      isHeaderFixed={page.props.isHeaderFixed}
    >
      {page as ReactNode}
    </MobileMainLayout>
  </Layout>
);
