import React from 'react';
import Head from 'next/head';

import { dangerouslySetInnerHTML } from 'src/lib/dangerouslySetInnerHTML';

type SiteLinksSearchBoxSchemaLdProps = {
  route: string;
};

const SiteLinksSearchBoxSchemaLd = ({
  route,
}: SiteLinksSearchBoxSchemaLdProps) => {
  const schemaData = `{
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url":"https://kupujemprodajem.com/",
    "potentialAction":
     {
     "@type": "SearchAction",
     "target": "${route}/pretraga?keywords={search_term_string}",
     "query": "required",
     "query-input":"required name=search_term_string"
     }
  }`;
  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={dangerouslySetInnerHTML(schemaData)}
      />
    </Head>
  );
};

export default SiteLinksSearchBoxSchemaLd;
