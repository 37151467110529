import dynamic from 'next/dynamic';
import Layout from '@layouts/responsive/Layout/Layout';

const ThreeColumnLayout = dynamic(
  () => import('@layouts/desktop/ThreeColumnLayout/ThreeColumnLayout')
);

export const mainLayout = (page: {
  props: {
    cookies: { [key: string]: string };
  };
}) => {
  return (
    <Layout>
      <ThreeColumnLayout cookies={page.props.cookies}>{page}</ThreeColumnLayout>
    </Layout>
  );
};
