import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';
import { fetchBanners } from '@store/banner/thunk';

type Params = {
  page: string;
  variable?: { [key: string]: string | number | boolean };
  groupId?: number;
  categoryId?: number;
};

const BANNER_DATA = {
  home: ({ isMobile }) => ({
    bannerPositionId: isMobile ? [101, 104] : [301, 302, 303, 304, 306],
    pageType: 'home',
  }),
  ads: ({ isMobile }) => ({
    bannerPositionId: isMobile ? [105] : [301, 302, 303, 304, 305, 308],
    pageType: 'ads',
  }),
  category: ({ isMobile, positionId }) => ({
    pageType: 'category',
    bannerPositionId: [
      ...(isMobile ? [] : [301, 302, 303, 304]),
      ...(positionId ? [positionId] : []),
    ],
  }),
  search: ({ isMobile, keywords, saved }) => ({
    pageType: 'search',
    bannerPositionId: [
      ...(isMobile ? [101, 104] : [20, 301, 302, 303, 304, 309, 311]),
      ...(keywords && !saved ? [33, 333] : []),
    ],
  }),
};

const useBanner = ({
  page,
  variable: { isMobile, positionId, keywords, saved, groupId, categoryId },
}: Params) => {
  const { query } = useRouter();
  const dispatch = useAppDispatch();

  const getBanners = useCallback(() => {
    if (page === 'ads' && !categoryId && !groupId) return;
    const bannerData = {
      ...BANNER_DATA[page]({ isMobile, positionId, keywords, saved }),
      ...query,
      ...(groupId && { groupId }),
      ...(categoryId && { categoryId }),
    };
    dispatch(fetchBanners(bannerData));
  }, [
    page,
    categoryId,
    groupId,
    isMobile,
    positionId,
    keywords,
    saved,
    query,
    dispatch,
  ]);

  useEffect(() => {
    getBanners();
  }, [getBanners]);
};

export default useBanner;
