import React from 'react';
import Head from 'next/head';

import { ROOT_URL } from 'src/lib/constants/env.constants';
import { dangerouslySetInnerHTML } from 'src/lib/dangerouslySetInnerHTML';

const OrganizationSchemaLd = () => {
  const schemaData = `{
    "@context" : "https://schema.org",
		"@type" : "Organization",
	  "name" : "KupujemProdajem",
	  "url" : "${ROOT_URL}",
		"logo": "${ROOT_URL}/images/KupujemProdajem_logo_RGB.svg",
		"sameAs" : ["https://www.facebook.com/KupujemProdajem",
              "https://plus.google.com/+KupujemprodajemPage/",
              "https://www.linkedin.com/company/kupujemprodajem-com",
              "https://en.wikipedia.org/wiki/Kupujemprodajem.com"]
  }`;
  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={dangerouslySetInnerHTML(schemaData)}
      />
    </Head>
  );
};

export default OrganizationSchemaLd;
